import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectAuthState = (state: AppState) => state.auth;

export const isAuthenticated = createSelector(selectAuthState, ({ isAuthenticated: isAuth }) => isAuth);
export const selectAuthLoadingState = createSelector(selectAuthState, ({ loading }) => ({ loading }));

export const selectUser = createSelector(selectAuthState, ({ user }) => user);
export const selectPromptLogin = createSelector(selectAuthState, ({ promptLogin }) => promptLogin);
